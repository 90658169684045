
import React, {useState, useRef, useEffect} from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Modal from 'react-modal';
import Slider from 'react-slick';


const  IllustrationPortfolio = () =>  {

    //Create intersection observer for video autoplay 
    useEffect(() => {
        const targets = document.querySelectorAll("video");

            const playVideo = (target)=>{
                
                
            const io = new IntersectionObserver((entries,observer)=>{
                entries.forEach(entry=>{
                    if(entry.isIntersecting){
                        const video=entry.target;
                        video.loop = true;
                        video.muted = true
                        
                        console.log('video on screen')
                        toggleVideo(video)
                       // observer.disconnect();
                    }
                })
            },{threshold:[0.7]});

            io.observe(target);

            //Function to play video
            const toggleVideo = async(targetVideo) => {
                try {
                   await targetVideo.play()
                } catch (error) {
                    console.log(error)
                }
            }
            }
            targets.forEach(playVideo);
    },[])

    const projects = require('../../data/illustrations.json')

    const [currentProject, setCurrentProject] = useState(null);
    const [modalShow, setModalShow] = useState(false)

    const sliderRef = useRef()

    //Handle project click
    const handleClick =  (e) => {

        const close = document.querySelector('.close-button');
        close.style.display == 'none' ? close.style.display = 'block' : close.style.display = 'none'

        console.log(e.target.id)

        let text;

        //Find array target
        let object = projects.find(o => o.name == e.target.id)
        object.text != false ? text = object.text : text = false;        

        setCurrentProject({
            img: object.image,
            text:text,
            col:object.col,
            type:object.type,
            name:object.name
        })

        //Play video if video
        if (object.type === 'video') console.log(e.target)

        setModalShow(!modalShow)
       
    }

    //Close modal
    const handleCloseModal = () => {
        const close = document.querySelector('.close-button');
        close.style.display == 'none' ? close.style.display = 'block' : close.style.display = 'none'
        
        setModalShow(!modalShow)
    }

    //Modal styling
    const modalStyle = {
        content : {
            margin:'auto',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            padding:'10px',
            background:'transparent',
            overflowX:'visible',
            border:'none',
            overflowY:'auto',
            overflowX:'auto',
            maxWidth:'85vw',
            maxHeight:'95vh',
            inset:'20px',
            width:'fit-content'

        }

    }
    const textModalStyle = {
        content : {
            margin:'0 auto',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            padding:'0',
            background:'rgba(247, 247, 247, 0.85)',
            backdropFilter:'blur(10px)',
            overflowX:'visible',
            border:'none',
            overflowY:'auto',
            minWidth:'70vw',
            maxWidth:'90vw',
            maxHeight:'100vh',
            overflowX:'hidden'
            
        }

    }

    //Slick slider config
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
        autoplay:false,
        autoPlaySpeed:1500
      };

      //Slick slider functions
      const nextSlide = () => {
        sliderRef.current.slickNext();
        }

        const prevSlide = () => {
            sliderRef.current.slickPrev()
        }
    
    return (
  
  <section id = 'illustration-portfolio-section' style = {sectionStyle}>
    

    <div style = {closeButtonStyle} className = 'close-button'><img src = '/main-arrow-back.svg' style = {arrowStyle} onClick = {() => handleCloseModal()}></img></div>

      
      <Modal 
      isOpen = {modalShow}
      onRequestClose={handleCloseModal}
      style = { currentProject && currentProject.text ? textModalStyle : modalStyle}
      >






            <div className = 'row modal-image-row' style = {{alignItems:'center', height:'100%'}}>

          

          {
              currentProject != null ?

              currentProject.text ? currentProject.img.map(img => {
                  return (

                    currentProject.name != 'the-burning' ? 
                            <>
                          <div className = 'col-lg-6 modal-text-left'>
                              <div className = 'image-text-wrapper' style = {textWrapperStyle}>
                              <h2 style = {textHeadingStyle} className = 'modal-heading'>{currentProject.text.title}</h2>
                              <p style = {projectTextStyle} className='w-75 text-center mx-auto modal-text'>{currentProject.text.subtext}</p>
                              </div>
                          </div>
                          <div className = 'col-lg-6 text-center modal-text-right'>
                          <img src = {`${img.src}`} style = {textImageStyle} className = 'img-fluid modal-text-image'></img>

                          </div>
                          </>
                      :
                    

                      <div className = {`${img.col} text-center`}> 
                      <img src = {`${img.src}`} style = {{maxHeight:'500px',maxWidth:'100%', }} className = 'img-fluid mt-3' ></img>

                      </div>

                      
                  )
              } )
                :   
                currentProject.type !== 'video' ? 
                     currentProject.img.map(img => {
                         return (
                            
                                 <div className = {`${img.col} text-center`}>
    
                             <img src = {`${img.src}`} className = 'mb-3'style = {{width:'100% ',maxHeight:'85vh' }} ></img>

                             </div> 
                             
                         )
                     } )
                  :  currentProject.img.map(img => {
                        return (

                            
                                 <div className =  {`${img.col} text-center`}>
                                  <video className = 'mb-4'style = {{maxWidth:'100%',height:'95%',maxHeight:'85vh' }} controls playsInline muted  autoplay loop   >
                               <source src = {`${img.src}`}></source>
                            </video>
                            
                            </div>
                        )

                  })
                  :

                   <></>

                   
                     
          }

            </div>

      </Modal>

       <div className = 'illustrations-heading-wrapper text-center'>
        <h1 className = 'illustrations-heading' style = {headingStyle}>Illustration Portfolio</h1>
        <p className = 'illustrations-subtext' style = {subtextStyle}><span className = 'line-1'>Select a project to view</span> <span className = 'line-2'>in full screen</span></p>

        <div className = 'illustrations-projects-wrapper container-fluid' style = {projectsWrapperStyle}>
            <div className = 'row'>

                {

                    
                    projects.map(project => {
                        if(project.orientation === 'landscape') {
                            return (
                                project.image.length === 1 ?

                                project.type === 'image' ? 
                                <div className =  'col-lg-12  col-md-12 illustration-project-wrapper mt-4' onClick = {(e) => handleClick(e)}>
                                    <img src = {`${project.image[0].src}`} id = {`${project.name}`} style = {mainImageStyle} className = 'illustration-media' loading = 'lazy'></img>
                                </div> 
                                :  <div className =  'col-lg-12 col-md-12 illustration-project-wrapper mt-4' onClick = {(e) => handleClick(e)}>
                                <video id = {`${project.name}`} className = 'illustration-media' playsinline controls>
                                    <source src = {`${project.image[0].src}`}></source>
                                </video>
                                </div>
                                : 
                                <div className = 'col-lg-12 col-md-12 illustration-project-wrapper mt-4' onClick = {(e) => handleClick(e)}>
                                <Slider {...sliderSettings} style = {{height:'100%'}} ref = {sliderRef}>
                                {  
                                    project.image.map(image => (
                                        project.type === 'image' ? 
                                        <div style = {{height:'100%'}} className = 'slick-container'>
                                            <img src = {`${image.src}`} style = {mainImageStyle} className = 'illustration-media' id = {`${project.name}`} loading = 'lazy'></img> 
                                        </div> 
                                        : 
                                        <div style = {{height:'100%'}} className = 'slick-container'>
                                        <video id = {`${project.name}`} playsinline controls>
                                             <source src = {`${image.src}`}></source>
                                          </video>
                                          </div>
                                    ))
                                }
     
                                </Slider>
                                </div>
                            )
                        } else {
                            return (

                                project.image.length === 1 ? 
                                
                                project.type === 'image' ? 
                                <div className =  {`${project.col}  illustration-project-wrapper mt-4`} onClick = {(e) => handleClick(e)}>
                                <img src = {`${project.image[0].src}`} id = {`${project.name}`} style = {imageStyle} className = 'illustration-media' loading = 'lazy'></img>
                            </div>   
                            : <div className =  {`${project.col}  illustration-project-wrapper mt-4`} onClick = {(e) => handleClick(e)}>
                             <video style = {imageStyle} className = 'illustration-media' id = {`${project.name}`}  playsInline muted loop controls>
                                 <source src = {`${project.image[0].src}`}  ></source>
                             </video>
                             </div>
                            : <div className = {`${project.col}  illustration-project-wrapper mt-4`} onClick = {(e) => handleClick(e)}>
                            <Slider {...sliderSettings} style = {{height:'100%'}} ref = {sliderRef}>
                            {

                                project.image.map(image => (
                                    project.type === 'image' ?
                                    <div style = {{height:'100%'}} className = 'slick-container'>
                                        <img src = {`${image.src}`} style = {imageStyle} className = 'illustration-media' id = {`${project.name}`} loading = 'lazy'></img> 
                                    </div>
                                    :<div style = {{height:'100%'}} className = 'slick-container'>
                                    <video style = {imageStyle} className = 'illustration-media' id = {`${project.name}`} controls  playsInline loop muted >
                                    <source src = {`${image.src}`} ></source>
                                </video>
                                </div>
                                ))
                                
                            }
 
                            </Slider>
                            </div>
                            )

                        }
                    })
                }
                
                
            </div>
        </div>
    </div>
    
  </section>
    ) 
}


const sectionStyle = {
    paddingLeft:'100px',
    paddingRight:'100px',
    minHeight:'100vh',
    color:'#464748'
}

const headingStyle = {
    fontSize:'40px',
    marginTop:'50px',
    fontWeight:'normal',
    color:'#fd0f1b'
}

const subtextStyle = {
    fontSize:'22px'
}

const imageStyle = {
    width:'100%',
    height:'530px',
    objectFit:'cover'
}

const mainImageStyle = {
    height:'550px',
    width:'100%',
}

const textImageStyle = {
    height:'600px'
}

const projectsWrapperStyle = {
    marginTop:'50px'
}

const textWrapperStyle = {
    height:'100%',
    justifyContent:'center',
    alignItems:'center',
    display:'flex',
    flexDirection:'column',
}

const textHeadingStyle = {
    fontSize:'44px',
    fontWeight:'regular',
    marginBottom:'30px',
    color:'#464748'
}

const projectTextStyle = {
    fontSize:'22px',
    color:'#464748'
}

const crossStyle = {
    fontSize:'32px',
    position:'absolute',
    right:'0'
}

const closeButtonStyle = {
    zIndex:'999',
    position:'fixed',
    top:'10px',
    left:'20px',
    display:'none'
    
}

const arrowStyle = {
height:'30px'
}

export default IllustrationPortfolio;
