import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import IllustrationPortfolio from "../components/IllustrationPortfolio"

const illustrations = () => (
  <Layout>
    <Seo title="Portfolio" />
    
    <IllustrationPortfolio />
  </Layout>
)



export default illustrations
